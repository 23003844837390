.HomeContainer {
    text-align: center;
    padding: 5px;
}

.HomeTitle {
    padding: 10px;
    font-weight: bold;
    font-size: 24px;
    background: rgb(255, 255, 255);
    color: rgb(131 107 67);
}

.NewProductsContainer {
    overflow-y: auto;
    padding: 5px;
}

.AboutMeContainer {
    overflow-y: auto;
    padding: 5px;
}

.ProductTypeContainer {
    overflow-y: auto;
    padding-bottom: 10px;
}

.ProductTypeImageContainer {
    cursor: pointer;
    padding-top: 10px;
}

.ProductTypeImageContainer:hover .centered {
    font-size: 30px;
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    background: rgb(0 0 0 / 17%);
    color: azure;
    padding-top: 50%;
    padding-bottom: 50px;
    font-size: 34px;
    font-weight: 700;
    width: 95%;
    height: 100%;
    z-index: 1;
}

.AboutMeText{
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(47, 48, 45, 0.562);
    color: azure;
    padding: 50px;
    font-size: 40px;
    font-weight: bold;
    width: 100%;
    z-index: 1;
}

.AboutMeTextActive{
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(47, 48, 45, 0.562);
    color: azure;
    padding: 50px;
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    z-index: 1;
}